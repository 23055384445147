<template>
  <div style="padding-top: 10px">
    <el-card>
      <el-row class="table-search">
        <el-col :span="22">
          <el-form :model="searchCondition" :inline="true" size="mini">
            <el-form-item label="选择组别">
              <company-select
                  :multiple="true"
                  @handleSelect="selectDept"
              />
            </el-form-item>
            <el-form-item label="签约起始时间段">
              <el-date-picker
                  v-model="searchCondition.date_range"
                  type="monthrange"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  @change="changeMonth"
                  value-format="yyyy-MM-01"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="签约时长">
              <el-input
                  placeholder="当月起12个月内"
                  v-model="searchCondition.sign_range"
                  clearable
              >
              </el-input>
            </el-form-item>

            <el-form-item label="单月总营收大于等于">
              <el-input
                  placeholder="请输入单月营收"
                  v-model="searchCondition.val"
                  clearable
              >
                <template slot="append">万</template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary"  icon="el-icon-search" @click="doSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;vertical-align: top">
          <export-link
              ref="growthExportLink"
              :lint-title="`导出表格`"
              :can-export="userPermissions.indexOf('growth_export') !== -1"
              @export="exportData"
          />
        </el-col>

      </el-row>
    </el-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ExportLink from "@/components/export/ExportLink";

export default {
  name: "GrowthSearch",
  data() {
    return {
      searchCondition: {
        group_ids: [],
        date_range: null,
        sign_range: null,
        val: null,
        is_export: false
      },
      loadingDownload: false,
    }
  },
  components: {
    ExportLink
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(val) {
      this.searchCondition.group_ids = val
      this.doSearch()
    },
    changeMonth() {
      this.doSearch()
    },
    doSearch() {
      let searchCondition = this.handleSearchCondition()
      this.$emit('doSearch', searchCondition)
    },

    handleSearchCondition() {
      let condition = {}
      this.searchCondition.group_ids ? condition.group_ids = this.searchCondition.group_ids : null
      if (this.searchCondition.date_range) {
        condition.sign_start = this.searchCondition.date_range[0]
        condition.sign_end = this.searchCondition.date_range[1]
      }
      if (this.searchCondition.sign_range) {
        let sign_range = Math.floor(this.searchCondition.sign_range)
        if (sign_range > 12) sign_range = 12
        this.searchCondition.sign_range = sign_range
        condition.sign_range = sign_range
      }

      this.searchCondition.val ? condition.val = this.searchCondition.val * 10000 : null
      this.searchCondition.is_export ? condition.is_export = this.searchCondition.is_export : null

      return condition
    },

    async exportData() {
      this.searchCondition.is_export = true
      this.loadingDownload = true
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `孵化分析`
        let response = await this.$api.getArtistGrowthExport(searchCondition)


        this.$refs.growthExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  }
}
</script>

<style scoped>
.export-excel {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}
</style>
