<template>
  <div>
    <growth-search @doSearch="doSearch" @export="exportData"></growth-search>
    <growth-list :search-condition="searchCondition" :is-export="isExport"></growth-list>
  </div>
</template>

<script>
import GrowthSearch from "@/pages/revenue/growth/GrowthSearch";
import GrowthList from "@/pages/revenue/growth/GrowthList";
export default {
  name: "GrowthIndex",
  components: {
    GrowthSearch,
    GrowthList
  },
  data() {
    return {
      searchCondition: {
        group_ids: null,
        sign_start: null,
        sign_end: null,
        sign_range: null,
        val :null
      },
      isExport: false
    }
  },
  methods: {
    doSearch(val) {
      this.searchCondition = val
    },
    exportData() {
      this.isExport = true
    }
  }
}
</script>

<style scoped>

</style>
