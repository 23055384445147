<template>
  <div>
    <el-row :gutter=15>
      <el-col :span="span">
        <el-card style="margin-top: 10px" :span="12">
          <el-row class="default-table">
            <el-col :span="24">
              <div
                  v-loading="loadingStatus"
                  :element-loading-text="loadingText"
                  element-loading-spinner="el-icon-loading"
              >
                <ape-table ref="apeTable"
                   :data="dataList"
                   :columns="[]"
                   :loading="loadingStatus"
                   border
                   row-key="id"
                   id="growthTable"
                   @changeTableSort="changeTableSort"
                >
                  <el-table-column
                      prop="group_name"
                      label="组别"
                      align="center"
                      min-width="100">
                    <template slot-scope="{row}">
                      <span >{{ row.groupName }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      prop="nickname"
                      label="红人昵称"
                      align="center"
                      min-width="100">
                    <template slot-scope="{row}">
                      <span >{{ row.nickname }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      prop="coopDuration"
                      label="合作时长"
                      align="center"
                      min-width="100"
                      @changeTableSort="changeTableSort"
                      sortable="custom"
                  >
                    <template slot-scope="{row}">
                      <span>{{ row.coopDuration }}个月</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      prop="firstRevenueCostMonth"
                      label="首次达成历时"
                      align="center"
                      min-width="100"
                      sortable='custom'
                  >
                    <template slot-scope="{row}">
                      <span>{{ row.firstRevenueCostMonth || row.firstRevenueCostMonth === 0 ? row.firstRevenueCostMonth+'个月' : '-' }}</span>
                    </template>
                  </el-table-column>


                  <el-table-column
                      prop="firstRevenueVal"
                      label="首次达成金额"
                      align="center"
                      min-width="100"
                      sortable='custom'
                  >
                    <template slot-scope="{row}">
                      <span>{{ moneyFormat(row.firstRevenueVal ? row.firstRevenueVal : '-') }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      prop="maxRevenueMonth"
                      label="最高月份"
                      align="center"
                      min-width="100"
                      sortable='custom'
                  >
                    <template slot-scope="{row}">
                      <span>{{ row.maxRevenueMonth ? row.maxRevenueMonth+'月' : '-' }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      prop="maxRevenueVal"
                      label="最高月份金额"
                      align="center"
                      min-width="100"
                      sortable='custom'
                  >
                    <template slot-scope="{row}">
                      <span>{{ moneyFormat(row.maxRevenueVal ? row.maxRevenueVal : '-')}}</span>
                    </template>
                  </el-table-column>


                  <el-table-column
                      prop="totalRevenueItems"
                      label="几个月达成条件"
                      align="center"
                      min-width="100"
                      sortable='custom'
                  >
                    <template slot-scope="{row}">
                      <span >{{ row.totalRevenueItems ? row.totalRevenueItems : '-'}}</span>
                    </template>
                  </el-table-column>
                </ape-table>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>

      <el-col :span="8" v-if="searchCondition.val && searchCondition.sign_range">
        <div class="report" style="margin-top: 10px">
          <div class="note is-always-shadow">
            备注：
            <p>1、表格里显示为所有自定义时间内签约的红人，将同时满足签约时长和 单月总营收条件红人排列在前，并标记表格底色</p>
            <p>2、解约红人不计算总数和达成数</p>
          </div>
          <el-card style="margin-top: 10px">
            <h2 style="text-align: center">分析简报</h2>
            <div style="font-size: 16px; line-height: 30px">
              <p>
                1、在选定组织范围内，以 <span class="notice">{{ searchCondition.sign_start }}</span>
                至<span class="notice"> {{ searchCondition.sign_end }}</span> 签约的红人为分析标的，共计 <span class="notice">{{ info.total }}</span> 人，
                合作时长<span class="notice">{{ searchCondition.sign_range }}个月以内（含{{ searchCondition.sign_range }}个月）</span>
                且单月总营收≥ <span class="notice">{{ searchCondition.val }}</span>，
                计<span class="notice">{{ info.reachTotal }}</span>人占当期签约红人的<span class="notice">{{ info.reachRate }}%</span>。
                备注：解约红人不计算总数和达成数
              </p>
              <p>
                2、满足单月总营收≥ <span class="notice">{{ searchCondition.val }}</span> 的红人，
                平均首次达成该营收规模耗时 <span class="notice">{{ info.avgFirstRevenueCostMonth }}</span>个月，
                最快达成该营收规模的红人为 <span class="notice">（{{ info.minFirstRevenueArtist }}）</span>
                耗时 <span class="notice">{{ info.minFirstRevenueCostMonth }} </span>个月
              </p>
            </div>

          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
export default {
  name: "GrowthList",
  components: {
    ApeTable
  },
  data() {
    return {
      loadingStatus: false,
      loadingText: '加载中...',
      dataList: [],
      info: [],
      span:24,
      orderSort: {firstRevenueVal: 'desc'},
    }
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          group_ids: null,
          sign_start: null,
          sign_end: null,
          sign_range: null,
          val: null
        }
      }
    },
    isExport: {
      type: Boolean
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler(val) {
         if (! val.sign_range || ! val.val) {
           this.span = 24
         } else {
           this.span = 16
         }
         this.initList()
      }
    },
    isExport: {
      handler(val) {
        val ? this.exportData() : null
      }
    }
  },
  methods: {
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.group_ids) {
        condition.group_ids = this.searchCondition.group_ids
      }

      if (this.searchCondition.sign_start) {
        condition.sign_start = this.searchCondition.sign_start
      }

      if (this.searchCondition.sign_end) {
        condition.sign_end = this.searchCondition.sign_end
      }

      if (this.searchCondition.sign_range) {
        condition.sign_range = this.searchCondition.sign_range
      }

      if (this.searchCondition.val) {
        condition.val = this.searchCondition.val
      }

      if (this.orderSort) {
        condition.order = this.orderSort
      }

      return condition
    },
    async initList() {
      this.loadingStatus = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.group_ids) {
        let {list, info}  = await this.$api.getArtistGrowthList(searchCondition)
        this.$nextTick(() => {
          this.dataList = list
          this.info = info
        })
      }
      this.loadingStatus = false
    },

    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.initList()
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
  }
}
</script>

<style scoped>
.notice {
  color: red; font-weight: bold; font-size: 18px
}

.note {
  background-color: #fdf6ec;
  border-color: #faecd8;
  color: #e6a23c;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>


<style>
 .red-row {
  background-color: #fef0f0 !important;
}
</style>
